/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'closer': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M10.94 12L8.817 9.879a.75.75 0 011.06-1.06L12 10.939l2.12-2.12a.75.75 0 111.061 1.06L13.061 12l2.12 2.121a.748.748 0 01-.237 1.234.75.75 0 01-.823-.174L12 13.061l-2.121 2.12a.75.75 0 01-1.06-1.06L10.938 12z" _fill="#ED2121"/><path pid="1" d="M12 21a9 9 0 100-18 9 9 0 000 18zm0 1.5a10.5 10.5 0 110-21 10.5 10.5 0 010 21z" _fill="#ED2121"/>'
  }
})
