/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'instagramShare': {
    width: 1214,
    height: 1214,
    viewBox: '0 0 1214 1214',
    data: '<path pid="0" d="M607 1214c-82.198 0-160.839-16-235.924-48.01-75.084-32.01-139.697-75.09-193.837-129.23-54.14-54.139-97.215-118.752-129.224-193.836C16.005 767.839 0 689.198 0 607c0-82.198 16.005-160.839 48.015-235.924 32.01-75.084 75.084-139.697 129.224-193.837 54.14-54.14 118.753-97.215 193.837-129.224C446.161 16.005 524.802 0 607 0c82.198 0 160.839 16.005 235.924 48.015 75.084 32.01 139.697 75.084 193.836 129.224 54.14 54.14 97.22 118.753 129.23 193.837C1198 446.161 1214 524.802 1214 607c0 82.198-16 160.839-48.01 235.924-32.01 75.084-75.09 139.697-129.23 193.836-54.139 54.14-118.752 97.22-193.836 129.23C767.839 1198 689.198 1214 607 1214zm303.5-834.625c0-20.549-7.311-38.333-21.933-53.35-14.621-15.017-32.602-22.525-53.942-22.525h-455.25c-20.549 0-38.333 7.508-53.35 22.525-15.017 15.017-22.525 32.801-22.525 53.35v75.875h135.152c45.841-50.583 101.957-75.875 168.348-75.875 66.391 0 122.507 25.292 168.348 75.875H910.5v-75.875zM455.25 607c0 41.889 14.819 77.653 44.458 107.292C529.347 743.931 565.111 758.75 607 758.75c41.889 0 77.653-14.819 107.292-44.458C743.931 684.653 758.75 648.889 758.75 607c0-41.889-14.819-77.653-44.458-107.292C684.653 470.069 648.889 455.25 607 455.25c-41.889 0-77.653 14.819-107.292 44.458C470.069 529.347 455.25 565.111 455.25 607zm455.25-75.875h-90.102c9.485 26.082 14.227 51.374 14.227 75.875 0 63.229-22.13 116.974-66.391 161.234-44.26 44.261-98.005 66.391-161.234 66.391-63.229 0-116.974-22.13-161.234-66.391-44.261-44.26-66.391-98.005-66.391-161.234 0-24.501 4.742-49.793 14.227-75.875H303.5v303.5c0 21.34 7.311 39.321 21.933 53.942 14.621 14.622 32.602 21.933 53.942 21.933h455.25c21.34 0 39.321-7.311 53.942-21.933 14.622-14.621 21.933-32.602 21.933-53.942v-303.5z" _fill="#7B6461"/>'
  }
})
