
import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    souscriptionPosition: 1,
    productType: 'automobile',
    devis: [],
    newDevis: null,
    token: null,
    manquant: null,
    sinistre: null,
    agence: false,
    myAgence: null,
    souscritionDetails: null,
    userConcern: null,
    isCommercial: true,
    sessionExpire: null
    // additionalGuarantee: null
  },

  mutations: {
    save_expire (state, position) {
      state.sessionExpire = position
    },
    save_commercial (state, position) {
      state.isCommercial = position
    },
    save_agence (state, position) {
      state.agence = position
    },
    update_allDevis (state, position) {
      state.devis = position
    },
    save_concern (state, user) {
      state.userConcern = user
    },
    save_souscription (state, data) {
      state.souscritionDetails = data
    },
    my_agence (state, position) {
      state.myAgence = position
    },
    save_position (state, position) {
      state.souscriptionPosition = position
    },
    save_product (state, product) {
      state.productType = product
    },
    save_devis (state, devis) {
      state.newDevis = devis
    },
    save_doc (state, doc) {
      state.manquant = doc
    },
    save_sinistre (state, sin) {
      state.sinistre = sin
    },
    add_devis (state, devis) {
      state.devis.push(devis)
    },
    delete_devis (state, devis) {
      const all = state.devis
      for (const item in all) {
        if (all[item].id === devis) {
          state.devis.splice(item, 1)
        }
      }
    },

    login (state, a) {
      state.token = a
    },

    logout (state, a) {
      state.token = null
      state.sinistre = null
      state.sinistre = null
      state.newDevis = null
      state.souscriptionPosition = 1
      state.manquant = null
      state.devis = []
      state.agence = false
      state.myAgence = null
      state.userConcern = null
      state.souscritionDetails = true
      state.isCommercial = true
      state.sessionExpire = null
    }

    // save_add_guarantee (state, a) {
    //   state.additionalGuarantee = a
    // }
  },

  actions: {
    saveExpireSession ({ commit }, eya) {
      commit('save_expire', eya)
    },
    saveCommercial ({ commit }, eya) {
      commit('save_commercial', eya)
    },
    saveAgence ({ commit }, eya) {
      commit('save_agence', eya)
    },
    updateAllDevis ({ commit }, eya) {
      commit('update_allDevis', eya)
    },
    saveConcern ({ commit }, eya) {
      commit('save_concern', eya)
    },
    saveSouscription ({ commit }, eya) {
      commit('save_souscription', eya)
    },
    saveMyAgence ({ commit }, eya) {
      commit('my_agence', eya)
    },
    position ({ commit }, eya) {
      commit('save_position', eya)
    },
    product ({ commit }, eya) {
      commit('save_product', eya)
    },
    saveFirstDevis ({ commit }, eya) {
      commit('save_devis', eya)
    },
    saveManquant ({ commit }, eya) {
      commit('save_doc', eya)
    },
    saveSinistre ({ commit }, eya) {
      commit('save_sinistre', eya)
    },
    addDevis ({ commit }, eya) {
      commit('add_devis', eya)
    },
    deleteDevis ({ commit }, eya) {
      commit('delete_devis', eya)
    },
    login ({ commit }, eya) {
      commit('login', eya)
    },
    logout ({ commit }, eya) {
      commit('logout', eya)
    }
    // saveAdittionalGuarantee ({ commit }, data) {
    //   commit('save_add_guarantee', data)
    // }
  },

  getters: {
    trackPosition: state => state.souscriptionPosition,
    trackProduct: state => state.productType,
    trackFirstDevis: state => state.newDevis,
    allDevis: state => state.devis,
    tokenState: state => state.token,
    manquant: state => state.manquant,
    sinistre: state => state.sinistre,
    trackAgence: state => state.agence,
    trackMyAgence: state => state.myAgence,
    souscriptionDetail: state => state.souscritionDetails,
    userConcern: state => state.userConcern,
    trackCommercial: state => state.isCommercial,
    trackExpirationDate: state => state.sessionExpire
  }
})
