/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_phone': {
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
    data: '<path pid="0" d="M5.919 8.027a7.922 7.922 0 002.878 1.862l1.466-1.166a.26.26 0 01.295 0l2.723 1.754a.777.777 0 01.128 1.236l-1.276 1.26a1.556 1.556 0 01-1.411.416 13.646 13.646 0 01-6.67-3.5A13.14 13.14 0 01.447 3.344a1.47 1.47 0 01.428-1.38L2.197.688a.778.778 0 011.214.125L5.223 3.5a.245.245 0 010 .292L4.029 5.227a7.68 7.68 0 001.89 2.8z" _fill="#7735DA"/>'
  }
})
