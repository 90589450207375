/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'whatsapp': {
    width: 50,
    height: 51,
    viewBox: '0 0 50 51',
    data: '<path pid="0" d="M13.216 43.324l.817.409c3.406 2.043 7.22 2.997 11.036 2.997 11.989 0 21.798-9.81 21.798-21.799 0-5.722-2.316-11.308-6.403-15.395s-9.537-6.403-15.395-6.403c-11.99 0-21.8 9.81-21.663 21.935 0 4.087 1.226 8.038 3.27 11.444l.545.818-2.18 8.038 8.175-2.044z" _fill="#00E676"/><path pid="1" d="M42.78 7.357C38.148 2.589 31.744 0 25.205 0 11.308 0 .136 11.308.272 25.068c0 4.36 1.227 8.584 3.27 12.398L0 50.41l13.215-3.406c3.679 2.044 7.766 2.998 11.854 2.998C38.829 50 50 38.693 50 24.932c0-6.676-2.589-12.943-7.22-17.575h-.001zm-17.575 38.42c-3.679 0-7.357-.953-10.49-2.86l-.818-.41-7.902 2.044 2.043-7.766-.545-.817C1.5 26.295 4.36 13.488 14.17 7.493 23.98 1.5 36.65 4.36 42.644 14.17c5.994 9.81 3.133 22.48-6.676 28.475-3.134 2.043-6.949 3.133-10.763 3.133zm11.989-15.122l-1.499-.682s-2.18-.953-3.542-1.634c-.136 0-.272-.137-.409-.137-.409 0-.68.136-.953.273 0 0-.137.136-2.044 2.316a.75.75 0 01-.681.409h-.136c-.137 0-.41-.137-.545-.273l-.682-.272c-1.498-.682-2.86-1.5-3.95-2.589-.273-.272-.682-.545-.954-.817-.954-.954-1.908-2.044-2.589-3.27l-.136-.273c-.136-.136-.136-.272-.273-.545 0-.272 0-.545.137-.68 0 0 .545-.682.953-1.09.273-.273.409-.682.682-.954.272-.41.408-.954.272-1.363-.136-.681-1.771-4.36-2.18-5.177-.272-.409-.545-.545-.953-.681h-1.5c-.272 0-.544.136-.817.136l-.136.136c-.273.136-.545.409-.817.545-.273.273-.41.545-.682.818-.953 1.226-1.498 2.724-1.498 4.223 0 1.09.272 2.18.681 3.134l.136.408c1.226 2.589 2.861 4.905 5.041 6.949l.545.545c.409.408.818.68 1.09 1.09 2.861 2.452 6.13 4.223 9.81 5.177.408.136.953.136 1.362.272h1.362c.681 0 1.499-.272 2.044-.545.409-.272.681-.272.953-.545l.273-.272c.273-.273.545-.409.818-.681.272-.273.544-.545.68-.818.273-.545.41-1.226.546-1.907v-.954s-.137-.136-.41-.272z" _fill="#fff"/>'
  }
})
