/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_user': {
    width: 14,
    height: 16,
    viewBox: '0 0 14 16',
    data: '<path pid="0" d="M9.8 9.5c-.897 0-1.328.5-2.8.5-1.472 0-1.9-.5-2.8-.5A4.201 4.201 0 000 13.7v.8A1.5 1.5 0 001.5 16h11a1.5 1.5 0 001.5-1.5v-.8c0-2.319-1.881-4.2-4.2-4.2zm2.7 5h-11v-.8c0-1.487 1.212-2.7 2.7-2.7.456 0 1.197.5 2.8.5 1.616 0 2.34-.5 2.8-.5 1.487 0 2.7 1.213 2.7 2.7v.8zM7 9a4.501 4.501 0 000-9 4.501 4.501 0 000 9zm0-7.5c1.653 0 3 1.347 3 3s-1.347 3-3 3-3-1.347-3-3 1.347-3 3-3z" _fill="#fff"/>'
  }
})
