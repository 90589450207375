/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'auto': {
    width: 45,
    height: 45,
    viewBox: '0 0 45 45',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M22.475 44.147c12.17-.001 22.036-9.868 22.035-22.038C44.509 9.939 34.642.073 22.472.074 10.302.075.436 9.942.437 22.112c.001 12.17 9.868 22.036 22.038 22.035zm-7.35-31.596a4.805 4.805 0 012.84-.934h9.016c2.078 0 3.933 1.333 4.51 3.332l.83 3.334h.54c.693 0 1.155.444 1.155 1.11v1.112c0 .666-.461 1.11-1.154 1.11v7.778c0 .667-.46 1.111-1.154 1.111H29.4c-.693 0-1.154-.444-1.154-1.11v-1.111H16.703v1.112c0 .666-.462 1.11-1.154 1.11l-2.309.001c-.693 0-1.154-.444-1.154-1.11v-7.778c-.694 0-1.155-.445-1.155-1.111v-1.112c0-.666.461-1.11 1.154-1.11h.54l.83-3.334a4.506 4.506 0 011.67-2.4zm.638 2.955c.231-1 1.162-1.666 2.201-1.666l9.018-.001c1.038 0 1.97.666 2.2 1.666l.974 3.889-15.365.001.972-3.889zm.362 7.222c.924 0 1.732.778 1.732 1.667s-.808 1.666-1.732 1.666c-.923 0-1.731-.777-1.731-1.666 0-.889.808-1.667 1.731-1.667zm12.697 0c.924 0 1.732.777 1.732 1.666 0 .889-.808 1.666-1.732 1.666-.923 0-1.731-.777-1.731-1.666 0-.889.808-1.667 1.731-1.667z" _fill="#0269AD"/>'
  }
})
