/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'png': {
    width: 28,
    height: 28,
    viewBox: '0 0 28 28',
    data: '<g clip-path="url(#clip0_5711_17901)" _fill="#48A9A6"><path pid="0" d="M5.602 14.935h.933a.933.933 0 100-1.867h-.933v1.867z"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M1.867 2.8a2.8 2.8 0 012.8-2.8h15.32l6.147 6.147V25.2a2.8 2.8 0 01-2.8 2.8H4.667a2.8 2.8 0 01-2.8-2.8V2.8zm1.867 8.4h2.8a2.8 2.8 0 110 5.6H5.6v3.733H3.734V11.2zm14.933 0h5.6v1.867h-3.733v5.6H22.4v-2.8h1.866v4.666h-5.6V11.2zm-5.6 4.887v4.446H11.2V11.2h1.867v.713l1.867 3.733V11.2H16.8v9.333h-1.867v-.713l-1.867-3.733z"/></g><defs><clipPath id="clip0_5711_17901"><path pid="2" _fill="#fff" d="M0 0h28v28H0z"/></clipPath></defs>'
  }
})
