/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'facebookShare': {
    width: 1378,
    height: 1378,
    viewBox: '0 0 1378 1378',
    data: '<path pid="0" d="M688.999 117.13c-315.792 0-574.167 257.801-574.167 575.315 0 287.083 210.145 525.365 484.597 568.425V858.953H453.59V692.445h145.839V565.554c0-144.116 85.551-223.351 217.035-223.351 62.584 0 128.039 10.909 128.039 10.909v141.82h-72.345c-71.197 0-93.589 44.21-93.589 89.57v107.943h159.618L912.35 858.953H778.569v401.917a574.19 574.19 0 00347.371-194.64 574.228 574.228 0 00137.23-373.785c0-317.514-258.38-575.315-574.171-575.315z" _fill="#5579B0"/>'
  }
})
