/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chevron-left': {
    width: 13,
    height: 21,
    viewBox: '0 0 13 21',
    data: '<path pid="0" d="M11.5 2L3 10.5l8.5 8.5" _stroke="#909090" stroke-width="3.148"/>'
  }
})
