/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'delete': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" d="M6.75 6.75h1.125v6.75H6.75V6.75zM10.125 6.75h1.125v6.75h-1.125V6.75z" _fill="#FF4B55"/><path pid="1" d="M2.25 3.375V4.5h1.125v11.25A1.125 1.125 0 004.5 16.875h9a1.125 1.125 0 001.125-1.125V4.5h1.125V3.375H2.25zM4.5 15.75V4.5h9v11.25h-9zM6.75 1.125h4.5V2.25h-4.5V1.125z" _fill="#FF4B55"/>'
  }
})
