/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'souscript': {
    width: 44,
    height: 43,
    viewBox: '0 0 44 43',
    data: '<circle pid="0" cx="21.969" cy="21.469" r="21.094" _fill="#49BF7C"/><path pid="1" d="M30.825 16.45a.982.982 0 00-.54-.783l-7.874-3.938a.983.983 0 00-.881 0l-7.875 3.938a.986.986 0 00-.539.782c-.01.105-.946 10.599 8.455 14.78a.971.971 0 00.8 0c9.4-4.181 8.465-14.674 8.454-14.78zm-8.854 12.793c-6.664-3.22-6.979-10.095-6.924-12.07l6.924-3.462 6.919 3.46c.036 1.958-.323 8.877-6.92 12.072z" _fill="#fff"/><path pid="2" d="M20.987 22.046L18.73 19.79l-1.392 1.392 3.649 3.649 5.618-5.618-1.392-1.392-4.226 4.226z" _fill="#fff"/>'
  }
})
