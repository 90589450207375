/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'message': {
    width: 44,
    height: 43,
    viewBox: '0 0 44 43',
    data: '<circle pid="0" cx="21.969" cy="21.531" r="21.094" _fill="#FFC300"/><path pid="1" d="M17.75 17.313h8.438V19H17.75v-1.688zm0 3.375h5.906v1.687H17.75v-1.688z" _fill="#fff"/><path pid="2" d="M28.719 13.094h-13.5a1.69 1.69 0 00-1.688 1.687V29.97l4.5-3.375h10.688a1.69 1.69 0 001.687-1.688V14.781a1.69 1.69 0 00-1.687-1.687zm0 11.812h-11.25l-2.25 1.688V14.78h13.5v10.125z" _fill="#fff"/>'
  }
})
