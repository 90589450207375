/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'vespa': {
    width: 142,
    height: 134,
    viewBox: '0 0 142 134',
    data: '<path pid="0" d="M91.17 41.609a37.1 37.1 0 0118.556 32.145v42.415H87.617a15.915 15.915 0 01-15.004 10.616 15.914 15.914 0 01-15.004-10.616H35.5V73.754a37.096 37.096 0 0118.556-32.145 21.067 21.067 0 01-1.988-4.968H35.5V26.037h16.574a21.218 21.218 0 0141.078 0h16.574v10.604H93.152a21.313 21.313 0 01-1.977 4.968h-.005zM72.612 79.056a5.302 5.302 0 00-5.302 5.302v26.509a5.302 5.302 0 1010.604 0v-26.51a5.302 5.302 0 00-5.302-5.301zm0-37.113a10.604 10.604 0 100-21.207 10.604 10.604 0 000 21.207z" _fill="#fff" opacity=".2"/>'
  }
})
