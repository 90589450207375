import Vue from 'vue'
import Router from 'vue-router'
import pathResolver from '@/plugins/loader'
import store from '../store'

Vue.use(Router)
const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/login',
      name: 'homeSite',
      component: pathResolver(null, 'homeSite'),
      redirect: 'login',
      children: [
        {
          path: '/accueil',
          name: 'accueil',
          component: pathResolver('accueil'),
          meta: {
            requiresAuth: false
          }
        },
        {
          path: '/login',
          name: 'login',
          component: pathResolver('login'),
          meta: {
            requiresAuth: false
          }
        },
        {
          path: '/devs41/admin',
          name: 'admin',
          component: pathResolver('admin'),
          meta: {
            requiresAuth: false
          }
        }
      ]
    },

    {
      path: '/automobile',
      name: 'homeParcour',
      component: pathResolver(null, 'homeParcour'),
      redirect: '/automobile',
      children: [
        {
          path: '/automobile',
          name: 'automobile',
          component: pathResolver('automobile'),
          meta: {
            requiresAuth: false
          }
        },
        {
          path: '/moto',
          name: 'moto',
          component: pathResolver('moto'),
          meta: {
            requiresAuth: false
          }
        },
        {
          path: '/mrh',
          name: 'mrh',
          component: pathResolver('mrh'),
          meta: {
            requiresAuth: false
          }
        },
        {
          path: '/voyage',
          name: 'voyage',
          component: pathResolver('voyage'),
          meta: {
            requiresAuth: false
          }
        }
      ]
    },
    {
      path: '/points',
      name: 'homeMap',
      component: pathResolver(null, 'homeMap'),
      redirect: '/agences',
      children: [
        {
          path: '/agences',
          name: 'agences',
          component: pathResolver('agences'),
          meta: {
            requiresAuth: false
          }
        }
      ]
    },

    {
      path: '/',
      name: 'homePrivate',
      component: pathResolver(null, 'homePrivate'),
      redirect: '/home',
      children: [
        {
          path: '/home',
          name: 'home',
          component: pathResolver('home'),
          meta: {
            requiresAuth: true
          }
        },

        {
          path: '/prolongement',
          name: 'prolongement',
          component: pathResolver('prolongement'),
          meta: {
            requiresAuth: true
          }
        },

        {
          path: '/souscription',
          name: 'souscription',
          component: pathResolver('souscription'),
          meta: {
            requiresAuth: true
          }
        },

        {
          path: '/portefeuille',
          name: 'portefeuille',
          component: pathResolver('portefeuille'),
          meta: {
            requiresAuth: true
          }
        },

        {
          path: '/sinistre',
          name: 'sinistre',
          component: pathResolver('sinistre'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/devis',
          name: 'devis',
          component: pathResolver('devis'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/document',
          name: 'document',
          component: pathResolver('document'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/declaration',
          name: 'declaration',
          component: pathResolver('declaration'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/proposition',
          name: 'proposition',
          component: pathResolver('proposition'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/agences',
          name: 'agences',
          component: pathResolver('agences'),
          meta: {
            requiresAuth: false
          }
        }
      ]
    },
    {
      path: '/recapitulatif',
      name: 'homeRecap',
      component: pathResolver(null, 'homeRecap'),
      redirect: '/recapitulatif',
      children: [
        {
          path: '/recapitulatif',
          name: 'recapitulatif',
          component: pathResolver('recapitulatif'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/details',
          name: 'details',
          component: pathResolver('details'),
          meta: {
            requiresAuth: false
          }
        },
        {
          path: '/modification',
          name: 'modification',
          component: pathResolver('modification'),
          meta: {
            requiresAuth: true
          }
        }
      ]
    },
    {
      path: '/cgu',
      name: 'cgu',
      component: pathResolver('cgu'),
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/politique',
      name: 'politique',
      component: pathResolver('politique'),
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/*',
      name: 'broker',
      component: pathResolver('agence'),
      meta: {
        requiresAuth: false
      }
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.tokenState) {
      next()
      return
    }
    next('/login')
  } else {
    next()
  }
})

export default router
