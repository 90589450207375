/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'location': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<circle pid="0" cx="24" cy="24" r="24" _fill="#fff"/><path pid="1" d="M23.927 19.486a2.25 2.25 0 100 4.501 2.25 2.25 0 000-4.5zm0 3.375a1.125 1.125 0 110-2.25 1.125 1.125 0 010 2.25z" _fill="#49BF7C"/><path pid="2" d="M28.251 17.41a6.114 6.114 0 00-9.447 7.66l4.25 6.526a1.041 1.041 0 001.746 0l4.25-6.526a6.114 6.114 0 00-.799-7.66zm-.143 7.046l-4.18 6.418-4.181-6.418a5.012 5.012 0 01.652-6.251 4.988 4.988 0 017.056 0 5.012 5.012 0 01.653 6.251z" _fill="#49BF7C"/>'
  }
})
