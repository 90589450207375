/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'valid': {
    width: 28,
    height: 28,
    viewBox: '0 0 28 28',
    data: '<circle pid="0" cx="13.574" cy="13.574" r="13.574" _fill="#49BF7C"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M20.707 10.207L11 19.914l-6.207-6.207 1.414-1.414L11 17.086l8.293-8.293 1.414 1.414z" _fill="#fff"/>'
  }
})
