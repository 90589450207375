/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'notification': {
    width: 52,
    height: 52,
    viewBox: '0 0 52 52',
    data: '<circle pid="0" opacity=".1" cx="26" cy="26" r="26" _fill="#F44336"/><path pid="1" d="M26 38.664a2.076 2.076 0 002.007-1.773h-4.091A2.077 2.077 0 0026 38.664zM37.285 33.646a11.205 11.205 0 01-1.952-2.279 9.823 9.823 0 01-1.05-3.741v-3.842a8.494 8.494 0 00-.124-1.393 5.789 5.789 0 01-1.742-.653c.206.665.31 1.357.31 2.053v3.843a11.076 11.076 0 001.284 4.55c.537.85 1.174 1.634 1.898 2.333h-19.92c.724-.7 1.362-1.483 1.899-2.333a11.076 11.076 0 001.283-4.55v-3.85A6.845 6.845 0 0126 16.908a6.696 6.696 0 013.702 1.12 5.823 5.823 0 01-.202-1.361v-.49a8.229 8.229 0 00-2.582-.778v-.98a1.038 1.038 0 10-2.077 0v1.02a8.408 8.408 0 00-7.233 8.345v3.842a9.823 9.823 0 01-1.05 3.741 11.2 11.2 0 01-1.921 2.279.778.778 0 00-.265.583v1.058a.777.777 0 00.778.778h21.622a.777.777 0 00.778-.778v-1.058a.777.777 0 00-.265-.583z" _fill="#000"/><path pid="2" d="M35.334 20.555a3.889 3.889 0 100-7.778 3.889 3.889 0 000 7.778z" _fill="#F44336"/>'
  }
})
