/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'valid-green': {
    width: 60,
    height: 60,
    viewBox: '0 0 60 60',
    data: '<circle pid="0" cx="30" cy="30" r="30" _fill="#00BF53"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M46.478 22.312l-18.382 18.25-9.082-8.989 2.046-2.031 7.036 6.959 16.337-16.22 2.045 2.031z" _fill="#fff"/>'
  }
})
