/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'close': {
    width: 109,
    height: 113,
    viewBox: '0 0 109 113',
    data: '<g filter="url(#filter0_d_6024_14878)"><circle pid="0" cx="38" cy="38" r="21" _fill="#fff"/></g><path pid="1" d="M33 43.664l3.816-6.096L33.288 32h2.136l1.56 2.568c.176.32.36.648.552.984.192.32.392.64.6.96h.096l.528-.96c.176-.336.352-.664.528-.984L40.704 32h2.064l-3.528 5.784 3.792 5.88h-2.136l-1.704-2.712-.624-1.056a22.443 22.443 0 00-.648-1.032h-.096c-.208.336-.408.68-.6 1.032-.192.336-.384.688-.576 1.056l-1.584 2.712H33z" _fill="#FF4B55"/><defs><filter id="filter0_d_6024_14878" x="-33" y="-29" width="142" height="142" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="4"/><feGaussianBlur stdDeviation="25"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/><feBlend in2="BackgroundImageFix" result="effect1_dropShadow_6024_14878"/><feBlend in="SourceGraphic" in2="effect1_dropShadow_6024_14878" result="shape"/></filter></defs>'
  }
})
