/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'copy': {
    width: 33,
    height: 33,
    viewBox: '0 0 33 33',
    data: '<rect pid="0" opacity=".2" width="32.913" height="32.913" rx="5.877" _fill="#00856F"/><path pid="1" d="M9.723 8.922l-.003 2.285v11.308a3.498 3.498 0 003.499 3.498h9.275a2.424 2.424 0 01-2.284 1.615h-6.991a5.113 5.113 0 01-5.114-5.113V11.207c0-1.055.675-1.954 1.618-2.285zm8.209-2.828c.643 0 1.26.255 1.714.71l4.971 4.974c.454.454.71 1.07.71 1.712v9.025a2.422 2.422 0 01-2.423 2.422h-9.683a2.422 2.422 0 01-2.422-2.422v-14a2.422 2.422 0 012.422-2.421h4.71zm-.138 1.615h-4.573a.807.807 0 00-.807.807v13.999c0 .445.361.807.807.807h9.683a.807.807 0 00.808-.807v-8.883h-3.496a2.422 2.422 0 01-2.417-2.255l-.005-.166V7.71zm1.615 1.142v2.36c0 .41.305.746.699.8l.11.008 2.357-.001L19.41 8.85h-.001z" _fill="#00856F"/>'
  }
})
