/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'help': {
    width: 34,
    height: 33,
    viewBox: '0 0 34 33',
    data: '<rect pid="0" opacity=".2" x=".74" width="32.913" height="32.913" rx="5.877" _fill="#ED2733"/><path pid="1" d="M17.487 7.172a9.689 9.689 0 100 19.377 9.689 9.689 0 000-19.377z" _stroke="#ED2733" stroke-width="1.762" stroke-miterlimit="10"/><path pid="2" d="M14.404 13.903s.046-.963 1.078-1.793c.611-.492 1.344-.635 2.005-.645.602-.007 1.139.092 1.46.245.551.262 1.623.902 1.623 2.262 0 1.431-.936 2.082-2.002 2.797-1.067.715-1.356 1.49-1.356 2.293" _stroke="#ED2733" stroke-width="1.541" stroke-miterlimit="10" stroke-linecap="round"/><path pid="3" d="M17.158 23.026a1.101 1.101 0 100-2.202 1.101 1.101 0 000 2.202z" _fill="#ED2733"/>'
  }
})
