import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import install from './install'
import store from './store'
import './registerServiceWorker'
import 'bulma/css/bulma.css'
import * as VueSpinnersCss from 'vue-spinners-css'
import firebase from 'firebase/app'
import 'firebase/firestore'
import { firestorePlugin } from 'vuefire'
import VueChatScroll from 'vue-chat-scroll'
import VueSocialSharing from 'vue-social-sharing'
import * as VueGoogleMaps from 'vue2-google-maps'
import AOS from 'aos'
import 'aos/dist/aos.css'
import LogRocket from 'logrocket'

LogRocket.init('l7ywwf/aabbenin')

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDmxPl6DxUm5zw4GGROHZY1uYAI-_IkhBk',
    libraries: 'places'
  }
})

Vue.use(firestorePlugin)
Vue.use(VueSpinnersCss)
Vue.use(VueChatScroll)
Vue.use(VueSocialSharing)

firebase.initializeApp({
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
})
/* firebase.auth().onAuthStateChanged(user => {
    store.dispatch("fetchUser", user);
}) */
// firebase.analytics()

export const db = firebase.firestore()

Vue.use(install)
Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  store,
  render: h => h(App),
  mounted () {
    AOS.init()
  }
}).$mount('#app')
