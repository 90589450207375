/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'user': {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M15 28.776c7.608 0 13.776-6.168 13.776-13.776S22.608 1.224 15 1.224 1.224 7.392 1.224 15 7.392 28.776 15 28.776zM15 30c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15C6.716 0 0 6.716 0 15c0 8.284 6.716 15 15 15z" _fill="#fff"/><path pid="1" d="M17.8 15.5c-.897 0-1.328.5-2.8.5-1.472 0-1.9-.5-2.8-.5A4.201 4.201 0 008 19.7v.8A1.5 1.5 0 009.5 22h11a1.5 1.5 0 001.5-1.5v-.8c0-2.319-1.881-4.2-4.2-4.2zm2.7 5h-11v-.8c0-1.488 1.213-2.7 2.7-2.7.456 0 1.197.5 2.8.5 1.616 0 2.34-.5 2.8-.5 1.488 0 2.7 1.212 2.7 2.7v.8zM15 15a4.501 4.501 0 000-9 4.501 4.501 0 000 9zm0-7.5c1.653 0 3 1.347 3 3s-1.347 3-3 3-3-1.347-3-3 1.347-3 3-3z" _fill="#fff"/>'
  }
})
