/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'back': {
    width: 55,
    height: 55,
    viewBox: '0 0 55 55',
    data: '<rect pid="0" width="55" height="55" rx="20" _fill="#fff"/><path pid="1" d="M31.5 19L23 27.5l8.5 8.5" _stroke="#909090" stroke-width="3.148"/>'
  }
})
