/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_help': {
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
    data: '<path pid="0" d="M14 7c0-3.87-3.14-7-7-7-3.87 0-7 3.13-7 7s3.13 7 7 7c3.86 0 7-3.13 7-7zM7.7 8.48H6.14v-.43c0-.38.08-.7.24-.98.16-.28.46-.57.88-.89.41-.29.68-.53.81-.71.14-.18.2-.39.2-.62 0-.25-.09-.44-.28-.58-.19-.13-.45-.19-.79-.19-.58 0-1.25.19-2 .57l-.64-1.28c.87-.49 1.8-.74 2.77-.74.81 0 1.45.2 1.92.58.48.39.71.91.71 1.55 0 .43-.09.8-.29 1.11-.19.32-.57.67-1.11 1.06-.38.28-.61.49-.71.63-.1.15-.15.34-.15.57v.35zm-1.47 2.74c-.18-.17-.27-.42-.27-.73 0-.33.08-.58.26-.75.18-.17.43-.25.77-.25.32 0 .57.09.75.26.18.17.27.42.27.74 0 .3-.09.55-.27.72-.18.18-.43.27-.75.27-.33 0-.58-.09-.76-.26z" _fill="#7735DA"/>'
  }
})
