/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'house': {
    width: 113,
    height: 121,
    viewBox: '0 0 113 121',
    data: '<g opacity=".2" fill-rule="evenodd" clip-rule="evenodd" _fill="#fff"><path pid="0" d="M60.5 24.903l45.375 45.375v31.816a11.341 11.341 0 01-11.344 11.344H26.47a11.343 11.343 0 01-11.344-11.344V70.278L60.5 24.903zm37.813-5.997v26.469L83.186 30.25V18.906a3.781 3.781 0 013.782-3.781h7.562a3.781 3.781 0 013.781 3.781z"/><path pid="1" d="M55.154 11.341a7.562 7.562 0 0110.693 0l50.268 50.26a3.786 3.786 0 01-5.354 5.355l-50.26-50.268L10.24 66.956A3.786 3.786 0 114.886 61.6l50.267-50.26z"/></g>'
  }
})
