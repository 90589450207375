/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'souscription': {
    width: 61,
    height: 61,
    viewBox: '0 0 61 61',
    data: '<circle pid="0" cx="30.5" cy="30.5" r="30.5" _fill="#49BF7C" fill-opacity=".1"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M30.5 17c-1.164 0-3.11.447-4.941.945a103.061 103.061 0 00-4.872 1.468 2.6 2.6 0 00-1.762 2.13c-1.005 7.555 1.328 13.154 4.16 16.858a19.874 19.874 0 004.248 4.14c.65.46 1.255.813 1.768 1.054.473.223.98.405 1.399.405.419 0 .925-.182 1.399-.405.618-.3 1.21-.653 1.768-1.055a19.869 19.869 0 004.248-4.14c2.831-3.703 5.165-9.302 4.16-16.857a2.601 2.601 0 00-1.762-2.132 105.419 105.419 0 00-4.872-1.468C33.611 17.45 31.664 17 30.5 17zm3.621 8.684a.845.845 0 111.195 1.195l-5.062 5.062a.844.844 0 01-1.195 0l-2.531-2.531a.847.847 0 01.274-1.378.844.844 0 01.92.183l1.934 1.936 4.465-4.467z" _fill="#49BF7C"/>'
  }
})
