/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'radio-off': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<rect pid="0" x="1" y="1" width="22" height="22" rx="11" _stroke="#828282" stroke-width="2"/>'
  }
})
