/* eslint-disable */
require('./alert')
require('./alerte')
require('./arrow')
require('./auto-white')
require('./auto')
require('./back')
require('./benin')
require('./bug')
require('./calendar')
require('./calendrier')
require('./car')
require('./carte')
require('./cedeao')
require('./chevron-down')
require('./chevron-left')
require('./chevron-right')
require('./close')
require('./closer')
require('./copy')
require('./declaration')
require('./defile')
require('./delete')
require('./devis')
require('./dog')
require('./download')
require('./edit')
require('./exit')
require('./facebookShare')
require('./fuel')
require('./gazoil')
require('./happy')
require('./help')
require('./house')
require('./ic_feed')
require('./ic_help')
require('./ic_logo')
require('./ic_phone')
require('./ic_pin')
require('./ic_user')
require('./instagramShare')
require('./invalid')
require('./jpg')
require('./linkedinShare')
require('./location')
require('./map')
require('./mdi_alert')
require('./message')
require('./messengerShare')
require('./moto')
require('./mrh')
require('./notifAlerte')
require('./notification')
require('./notifSousc')
require('./pause')
require('./plane')
require('./play')
require('./png')
require('./prolonger')
require('./radio-off')
require('./radio')
require('./retour')
require('./risque')
require('./sante')
require('./search')
require('./seet-place')
require('./send')
require('./sinistre')
require('./souscript')
require('./souscription')
require('./starOff')
require('./starOn')
require('./thank-you')
require('./twiterShare')
require('./user')
require('./valid-green')
require('./valid')
require('./vehicule')
require('./vespa')
require('./voiture')
require('./voyage')
require('./whatsapp')
require('./whatsappShare')
