/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'twiterShare': {
    width: 1228,
    height: 1228,
    viewBox: '0 0 1228 1228',
    data: '<path pid="0" d="M614 24.56C288.457 24.56 24.56 288.457 24.56 614c0 325.543 263.897 589.44 589.44 589.44 325.542 0 589.44-263.897 589.44-589.44 0-325.543-263.898-589.44-589.44-589.44zm239.767 482.85c.245 5.034.307 10.069.307 14.981 0 153.5-116.722 330.394-330.271 330.394a327.567 327.567 0 01-177.937-52.19c9.026 1.105 18.297 1.535 27.691 1.535 54.401 0 104.442-18.482 144.167-49.673A116.35 116.35 0 01409.292 671.9a116.66 116.66 0 0052.374-2.026 116.169 116.169 0 01-93.144-113.836v-1.412c15.657 8.658 33.586 13.938 52.62 14.552a116.045 116.045 0 01-35.919-155.096 330.085 330.085 0 00239.276 121.387A116.108 116.108 0 01822.33 429.554a233.134 233.134 0 0073.68-28.182 116.664 116.664 0 01-51.024 64.286 233.256 233.256 0 0066.681-18.359 235.42 235.42 0 01-57.9 60.111z" _fill="#66B4FB"/>'
  }
})
