/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pause': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M10 18a1 1 0 01-1 1H8a1 1 0 01-1-1V6a1 1 0 011-1h1a1 1 0 011 1v12zm7 0a1 1 0 01-1 1h-1a1 1 0 01-1-1V6a1 1 0 011-1h1a1 1 0 011 1v12z" _fill="#0269AD"/>'
  }
})
