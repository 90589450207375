/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'jpg': {
    width: 28,
    height: 28,
    viewBox: '0 0 28 28',
    data: '<path pid="0" d="M13.066 14.933H14a.933.933 0 000-1.867h-.934v1.867z" _fill="#E97D19"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M1.867 2.8a2.8 2.8 0 012.8-2.8h15.32l6.147 6.147V25.2a2.8 2.8 0 01-2.8 2.8H4.667a2.8 2.8 0 01-2.8-2.8V2.8zm5.6 10.267H3.734V11.2h5.6v9.333h-5.6V16.8H5.6v1.867h1.866v-5.6zm3.734-1.867H14a2.8 2.8 0 010 5.6h-.934v3.733H11.2V11.2zm7.466 0h5.6v1.867h-3.733v5.6H22.4v-2.8h1.866v4.666h-5.6V11.2z" _fill="#E97D19"/>'
  }
})
