/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'auto-white': {
    width: 13,
    height: 14,
    viewBox: '0 0 13 14',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M6.383 13.013a6.383 6.383 0 100-12.767 6.383 6.383 0 000 12.767zM4.255 3.86c.236-.174.525-.27.822-.27H7.69c.602 0 1.14.386 1.307.965l.24.966h.157c.2 0 .334.129.334.322v.321c0 .194-.134.322-.334.322V8.74c0 .193-.134.322-.335.322H8.39c-.2 0-.334-.129-.334-.322v-.322H4.711v.322c0 .193-.133.322-.334.322h-.669c-.2 0-.334-.129-.334-.322V6.486c-.2 0-.334-.128-.334-.322v-.321c0-.193.133-.322.334-.322h.156l.241-.966c.078-.276.248-.52.484-.695zm.185.856a.643.643 0 01.637-.483H7.69c.301 0 .571.194.638.483l.282 1.127H4.158l.282-1.127zm.104 2.092c.268 0 .502.225.502.483 0 .257-.234.483-.502.483-.267 0-.501-.226-.501-.483 0-.258.234-.483.501-.483zm3.678 0c.268 0 .502.225.502.483 0 .257-.234.483-.502.483-.267 0-.501-.226-.501-.483 0-.258.234-.483.501-.483z" _fill="#fff"/>'
  }
})
