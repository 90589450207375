/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'alerte': {
    width: 50,
    height: 51,
    viewBox: '0 0 50 51',
    data: '<circle pid="0" cx="25.117" cy="25.386" r="24.759" _fill="#FE2834"/><path pid="1" d="M23.063 16.355a1.438 1.438 0 012.875 0v11.5a1.438 1.438 0 01-2.875 0v-11.5zM23.063 32.645a1.438 1.438 0 112.875 0 1.438 1.438 0 01-2.875 0z" _fill="#fff"/>'
  }
})
