/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'bug': {
    width: 44,
    height: 43,
    viewBox: '0 0 44 43',
    data: '<circle pid="0" cx="21.969" cy="21.594" r="21.094" _fill="#FF787F"/><path pid="1" d="M20.373 13.18a7.146 7.146 0 012.923 0l1.71-1.711 1.439 1.438-1.067 1.067a7.145 7.145 0 012.886 3.121h2.718v2.033h-2.104c.047.332.071.67.071 1.016v1.017h2.033v2.032h-2.033v1.017c0 .345-.024.684-.071 1.016h2.104v2.033h-2.718a7.114 7.114 0 01-12.859 0h-2.717v-2.033h2.103a7.17 7.17 0 01-.07-1.016v-1.017h-2.034v-2.032h2.033v-1.017c0-.345.025-.684.071-1.016h-2.104v-2.033h2.718a7.146 7.146 0 012.887-3.122l-1.068-1.067 1.44-1.436 1.71 1.711zm1.462 1.883a5.082 5.082 0 00-5.082 5.081v4.066a5.082 5.082 0 0010.163 0v-4.066a5.082 5.082 0 00-5.081-5.081zm-3.05 8.13h6.099v2.033h-6.098v-2.033zm0-4.065h6.099v2.033h-6.098v-2.033z" _fill="#fff"/>'
  }
})
