/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'plane': {
    width: 125,
    height: 114,
    viewBox: '0 0 125 114',
    data: '<g opacity=".2" clip-path="url(#clip0_5653_14595)"><path pid="0" d="M138.45 99.4H3.55A3.549 3.549 0 000 102.948v7.1a3.549 3.549 0 003.55 3.55h134.9a3.549 3.549 0 003.55-3.55v-7.1a3.55 3.55 0 00-3.55-3.55zM17.872 75.718a7.318 7.318 0 005.398 2.376l28.964-.04a14.56 14.56 0 006.576-1.58l64.557-32.76c5.933-3.01 11.251-7.308 14.87-12.937 4.062-6.32 4.504-10.892 2.9-14.123-1.6-3.232-5.489-5.606-12.924-6.09-6.623-.43-13.211 1.313-19.144 4.322L87.212 25.98 38.688 7.772a3.949 3.949 0 00-3.993-.247L20.106 14.93c-2.367 1.2-2.94 4.36-1.147 6.33L53.62 43.025l-22.9 11.622-16.052-8.092a3.951 3.951 0 00-3.565.005l-8.904 4.52C-.118 52.254-.728 55.321.963 57.31l16.909 18.409z" _fill="#fff"/></g><defs><clipPath id="clip0_5653_14595"><path pid="1" _fill="#fff" d="M0 0h125v114H0z"/></clipPath></defs>'
  }
})
