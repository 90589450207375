/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'notifSousc': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: '<circle pid="0" cx="20" cy="20" r="20" _fill="#49BF7C" fill-opacity=".1"/><g clip-path="url(#clip0_6510_15211)"><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M20 11.148c-.764 0-2.04.294-3.24.62a67.554 67.554 0 00-3.195.963 1.704 1.704 0 00-1.155 1.396c-.66 4.954.87 8.626 2.728 11.055a13.033 13.033 0 002.785 2.714c.427.302.823.534 1.16.692.31.146.642.265.917.265.274 0 .606-.12.917-.265a7.916 7.916 0 001.16-.692 13.031 13.031 0 002.785-2.714c1.857-2.43 3.387-6.1 2.728-11.055a1.705 1.705 0 00-1.156-1.397 69.092 69.092 0 00-3.194-.963c-1.2-.324-2.477-.619-3.24-.619zm2.375 5.695a.554.554 0 11.783.783l-3.32 3.32a.554.554 0 01-.783 0l-1.66-1.66a.554.554 0 11.783-.783l1.268 1.269 2.928-2.93z" _fill="#49BF7C"/></g><defs><clipPath id="clip0_6510_15211"><path pid="2" _fill="#fff" transform="translate(11.148 11.148)" d="M0 0h17.705v17.705H0z"/></clipPath></defs>'
  }
})
