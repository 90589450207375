/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'car': {
    width: 25,
    height: 24,
    viewBox: '0 0 25 24',
    data: '<mask id="svgicon_car_a" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M24.5 0H.5v24h24V0zM18 12a5.5 5.5 0 100-11 5.5 5.5 0 000 11z" _fill="#0269AD"/></mask><g mask="url(#svgicon_car_a)" _fill="#0269AD"><path pid="1" d="M21.272 10.155l-1.368-4.104A2.995 2.995 0 0017.059 4H7.941a2.995 2.995 0 00-2.845 2.051l-1.368 4.104A2 2 0 002.5 12v5c0 .738.404 1.376 1 1.723V21a1 1 0 001 1h1a1 1 0 001-1v-2h12v2a1 1 0 001 1h1a1 1 0 001-1v-2.277a1.99 1.99 0 001-1.723v-5a2 2 0 00-1.228-1.845zM7.941 6h9.117c.431 0 .813.274.949.684L19.113 10H5.887l1.105-3.316A1 1 0 017.941 6zM6 16a1.5 1.5 0 11.001-3.001A1.5 1.5 0 016 16zm13 0a1.5 1.5 0 11.001-3.001A1.5 1.5 0 0119 16z"/><path pid="2" d="M18.983 7.483v.982h1.208a2.916 2.916 0 01-2.19.982 2.95 2.95 0 01-2.947-2.946h-.982a3.92 3.92 0 006.875 2.585v1.343h.982V7.483h-2.946zm-.982-4.91a3.948 3.948 0 00-2.947 1.342V2.572h-.982V5.52h2.947v-.982H15.81A2.917 2.917 0 0118 3.554a2.95 2.95 0 012.946 2.947h.982a3.933 3.933 0 00-3.928-3.929z"/></g>'
  }
})
