/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'calendar': {
    width: 33,
    height: 33,
    viewBox: '0 0 33 33',
    data: '<rect pid="0" opacity=".2" width="32.913" height="32.913" rx="5.877" _fill="#ED2733"/><path pid="1" d="M21.5 10H20V8.375A.376.376 0 0019.625 8h-1.25a.376.376 0 00-.375.375V10h-4V8.375A.376.376 0 0013.625 8h-1.25a.376.376 0 00-.375.375V10h-1.5A1.5 1.5 0 009 11.5v11a1.5 1.5 0 001.5 1.5h11a1.5 1.5 0 001.5-1.5v-11a1.5 1.5 0 00-1.5-1.5zm-.188 12.5H10.688a.188.188 0 01-.187-.188V13h11v9.313a.188.188 0 01-.188.187z" _fill="#ED2733"/>'
  }
})
