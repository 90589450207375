/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'invalid': {
    width: 28,
    height: 28,
    viewBox: '0 0 28 28',
    data: '<circle pid="0" cx="13.871" cy="13.574" r="12.574" _stroke="#DDD" stroke-width="2"/>'
  }
})
