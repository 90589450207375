/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow': {
    width: 32,
    height: 24,
    viewBox: '0 0 32 24',
    data: '<path pid="0" d="M.94 10.94a1.5 1.5 0 000 2.12l9.545 9.547a1.5 1.5 0 102.122-2.122L4.12 12l8.486-8.485a1.5 1.5 0 10-2.122-2.122L.94 10.94zM32 10.5H2v3h30v-3z" _fill="#000"/>'
  }
})
