/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'prolonger': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<mask id="svgicon_prolonger_a" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M24 0H0v24h24V0zm-6.5 12a5.5 5.5 0 100-11 5.5 5.5 0 000 11z" _fill="#0269AD"/></mask><g mask="url(#svgicon_prolonger_a)" _fill="#0269AD"><path pid="1" d="M18.8 22H4.4a.9.9 0 01-.9-.9V4.9a.9.9 0 01.9-.9h14.4a.9.9 0 01.9.9v16.2a.9.9 0 01-.9.9zM8 10.3v1.8h7.2v-1.8H8zm0 3.6v1.8h5.5v-1.8H8z"/><path pid="2" d="M18.483 7.483v.982h1.208a2.916 2.916 0 01-2.19.982 2.95 2.95 0 01-2.947-2.946h-.982a3.92 3.92 0 006.875 2.585v1.343h.982V7.483h-2.946zm-.982-4.91a3.948 3.948 0 00-2.947 1.342V2.572h-.982V5.52h2.947v-.982H15.31a2.917 2.917 0 012.19-.983 2.95 2.95 0 012.946 2.947h.982a3.933 3.933 0 00-3.928-3.929z"/></g>'
  }
})
