/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'starOff': {
    width: 38,
    height: 36,
    viewBox: '0 0 38 36',
    data: '<path pid="0" d="M18.544 0l5.146 12.373 13.357 1.07-10.177 8.718 3.11 13.035-11.436-6.985-11.436 6.985 3.11-13.035L.04 13.444l13.358-1.071L18.544 0z" _fill="#C4C4C4"/>'
  }
})
