/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'benin': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M32 7.914a2.397 2.397 0 00-2.397-2.396H2.397A2.397 2.397 0 000 7.914V16h32V7.914z" _fill="#FFE15A"/><path pid="1" d="M2.397 26.483h27.206A2.397 2.397 0 0032 24.086V16H0v8.086a2.396 2.396 0 002.397 2.397z" _fill="#FF4B55"/><path pid="2" d="M2.382 26.483h8.285V5.518h-8.27A2.397 2.397 0 000 7.914v16.173a2.396 2.396 0 002.382 2.396z" _fill="#73AF00"/>'
  }
})
