/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'linkedinShare': {
    width: 1312,
    height: 1312,
    viewBox: '0 0 1312 1312',
    data: '<g clip-path="url(#clip0)" _fill="#126DC0"><path pid="0" d="M800.115 490.033c-27.798 0-48.38 7.011-64.493 15.867-2.583-9.184-11.07-15.908-21.074-13.899H592.204c-12.095-2.009-21.894 7.79-18.163 20.5v451c-3.731 11.48 6.068 21.32 18.163 20.5h122.344c12.095.82 21.894-9.02 21.894-21.115l-.041-272.568c-.041-.123-.492-13.161 7.749-22.14 6.601-7.134 17.958-10.783 33.661-10.783 26.158 0 37.802 10.701 42.148 34.645v270.846c-4.305 12.095 5.494 21.935 17.589 21.115h128.945c12.095.82 21.894-9.02 17.548-21.115V682.938c4.305-142.352-97.088-192.905-183.926-192.905zm144.443 452.968h-85.116V692.04c0-48.421-31.283-78.433-81.59-78.433-28.454 0-50.594 8.364-65.846 24.846-21.197 22.96-19.557 52.07-15.006 58.548v246h-82.902v-409.18h78.556v13.776a21.818 21.818 0 0012.185 19.743 21.808 21.808 0 0023.075-2.4l5.74-4.428c16.605-13.079 33.784-26.65 66.502-26.65 33.866 0 144.443 10.783 144.443 149.117v260.022h-.041zm-493.56-657.395c-46.002 0-83.394 37.392-83.394 83.394s37.392 83.394 83.394 83.394 83.394-37.392 83.394-83.394-37.392-83.394-83.394-83.394zm0 123c-21.812 0-39.606-17.794-39.606-39.606s17.794-39.606 39.606-39.606 39.605 17.794 39.605 39.606-17.793 39.606-39.605 39.606zm58.549 81.385H387.859c-12.095 0-21.894 9.799-18.86 22.509v451c-3.034 11.48 6.765 21.32 18.86 20.5h121.688c12.095.82 21.894-9.02 21.894-21.115v-451a21.898 21.898 0 00-21.894-21.894zM491.999 943h-82.246V533.82h82.246V943z"/><path pid="1" d="M655.997-1.394C293.476-1.394-1.396 293.478-1.396 656c0 362.52 294.872 657.39 657.393 657.39 362.523 0 657.393-294.87 657.393-657.39 0-362.522-294.87-657.394-657.393-657.394zm0 1271.004c-338.331 0-613.606-275.278-613.606-613.61S317.666 42.394 655.997 42.394C994.329 42.394 1269.6 317.668 1269.6 656s-275.271 613.61-613.603 613.61z"/></g><defs><clipPath id="clip0"><path pid="2" _fill="#fff" d="M0 0h1312v1312H0z"/></clipPath></defs>'
  }
})
