/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'notifAlerte': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: '<circle pid="0" opacity=".25" cx="20" cy="20" r="20" _fill="#FFAD1B"/><path pid="1" d="M10.173 25.311l8.793-14.716c.488-.793 1.648-.793 2.076 0l8.792 14.716c.489.794-.122 1.832-1.038 1.832H11.211c-.916 0-1.527-1.038-1.038-1.832z" _fill="#FFC107"/><path pid="2" d="M19.026 24.027c0-.122 0-.244.061-.366s.122-.183.184-.305c.06-.123.183-.123.305-.184.122-.06.244-.06.366-.06s.306 0 .428.06c.122.061.244.122.305.184.061.06.122.183.183.305s.061.244.061.366 0 .244-.06.367c-.062.122-.123.183-.184.305s-.183.122-.305.183-.245.061-.428.061-.305 0-.366-.06c-.061-.062-.244-.123-.305-.184-.062-.061-.123-.183-.184-.305-.06-.122-.06-.245-.06-.367zm1.71-1.893h-1.404l-.245-5.984h1.832l-.183 5.984z" _fill="#263238"/>'
  }
})
