/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'edit': {
    width: 15,
    height: 15,
    viewBox: '0 0 15 15',
    data: '<path pid="0" d="M2.109 12.826h1.065l6.562-6.562-1.065-1.066-6.562 6.563v1.065zm10.88-7.666L9.756 1.965 10.82.899c.291-.291.65-.437 1.075-.437.424 0 .782.146 1.074.437l1.065 1.066c.292.291.444.643.457 1.056.013.412-.127.763-.419 1.055L12.99 5.16zM1.348 14.348a.734.734 0 01-.542-.22.734.734 0 01-.219-.541v-2.15a.784.784 0 01.228-.551l7.837-7.837 3.234 3.234-7.837 7.837a.762.762 0 01-.552.228h-2.15z" _fill="#0269AD"/>'
  }
})
