/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'search': {
    width: 22,
    height: 21,
    viewBox: '0 0 22 21',
    data: '<path pid="0" d="M20.875 20l-4.477-4.494L20.875 20zM18.879 9.5a8.51 8.51 0 01-2.485 6.01 8.473 8.473 0 01-11.996 0 8.51 8.51 0 010-12.02 8.473 8.473 0 0111.996 0A8.51 8.51 0 0118.88 9.5v0z" _stroke="#909090" stroke-width="2" stroke-linecap="round"/>'
  }
})
