/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cedeao': {
    width: 32,
    height: 39,
    viewBox: '0 0 32 39',
    data: '<g clip-path="url(#clip0_6742_16722)"><path pid="0" d="M28.078 14.975h-3.686V13.86H22.78V1.881h2.034V0H7.541v1.88H9.57v11.981H7.957v1.114H4.269a3.85 3.85 0 00-3.84 3.84v14.09a3.85 3.85 0 003.84 3.84h1.114a2.035 2.035 0 104.072 0h13.402a2.036 2.036 0 004.072 0h1.152a3.85 3.85 0 003.84-3.84v-14.09a3.85 3.85 0 00-3.84-3.84h-.003zM10.222 1.88h11.903v11.98h-1.613v1.114h-8.677V13.86h-1.613V1.881zM6.536 31.065l-2.873-3.189 8.485-7.641 2.88 3.186-8.492 7.644zm11.751-13.131h4.607v4.301h-4.607v-4.301zm5.837 15.09a4.301 4.301 0 114.302-4.302v.008a4.295 4.295 0 01-4.294 4.293h-.008z" _fill="#F95428"/></g><defs><clipPath id="clip0_6742_16722"><path pid="1" _fill="#fff" d="M0 0h32v38.919H0z"/></clipPath></defs>'
  }
})
