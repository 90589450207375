/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'radio': {
    width: 15,
    height: 15,
    viewBox: '0 0 15 15',
    data: '<circle pid="0" cx="7.5" cy="7.5" r="6.5" _stroke="#49BF7C" stroke-width="2"/><circle pid="1" cx="7.5" cy="7.5" r="4.5" _fill="#49BF7C"/>'
  }
})
