/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'happy': {
    width: 22,
    height: 22,
    viewBox: '0 0 22 22',
    data: '<path pid="0" d="M18.778 3.222A10.928 10.928 0 0011 0C8.062 0 5.3 1.144 3.222 3.222A10.928 10.928 0 000 11c0 2.938 1.144 5.7 3.222 7.778A10.928 10.928 0 0011 22c2.938 0 5.7-1.144 7.778-3.222A10.928 10.928 0 0022 11c0-2.938-1.144-5.7-3.222-7.778zM11 20.69c-5.343 0-9.69-4.347-9.69-9.69 0-5.343 4.347-9.69 9.69-9.69 5.343 0 9.69 4.347 9.69 9.69 0 5.343-4.347 9.69-9.69 9.69z" _fill="#BDBDBD"/><path pid="1" d="M8.62 8.095a2.753 2.753 0 00-3.89 0 .655.655 0 10.927.926 1.442 1.442 0 012.037 0 .653.653 0 00.926 0 .655.655 0 000-.926zM17.268 8.095a2.753 2.753 0 00-3.889 0 .655.655 0 10.926.926 1.442 1.442 0 012.038 0 .652.652 0 00.925 0 .655.655 0 000-.926zM16.805 11.48H5.194a.655.655 0 00-.655.655 6.468 6.468 0 006.46 6.46 6.468 6.468 0 006.46-6.46.655.655 0 00-.654-.655zm-5.806 5.806A5.158 5.158 0 015.89 12.79h10.219a5.158 5.158 0 01-5.11 4.496z" _fill="#BDBDBD"/>'
  }
})
