/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'mdi_alert': {
    width: 21,
    height: 21,
    viewBox: '0 0 21 21',
    data: '<path pid="0" d="M11.375 12.25h-1.75V7.875h1.75v4.375zm0 3.5h-1.75V14h1.75v1.75zm-10.5 2.625h19.25L10.5 1.75.875 18.375z" _fill="#FA2828"/>'
  }
})
