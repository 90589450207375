/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'voiture': {
    width: 119,
    height: 135,
    viewBox: '0 0 119 135',
    data: '<g clip-path="url(#clip0_5938_14402)"><path pid="0" d="M175.125 39.23h-20.97l-5.828-14.572C142.338 9.678 128.043 0 111.907 0H67.424C51.29 0 36.994 9.678 31 24.658L25.172 39.23H4.205C1.47 39.23-.537 41.8.128 44.452l2.102 8.406a4.2 4.2 0 004.077 3.184h7.03c-4.704 4.108-7.734 10.08-7.734 16.812v16.813c0 5.646 2.158 10.742 5.604 14.686v18.939c0 6.189 5.02 11.208 11.208 11.208h11.209c6.189 0 11.208-5.019 11.208-11.208v-11.209H134.5v11.209c0 6.189 5.019 11.208 11.208 11.208h11.208c6.189 0 11.209-5.019 11.209-11.208v-18.939c3.446-3.94 5.604-9.037 5.604-14.686V72.854c0-6.732-3.03-12.704-7.73-16.812h7.029a4.2 4.2 0 004.077-3.184l2.102-8.406c.662-2.652-1.345-5.223-4.081-5.223zM51.813 32.983a16.814 16.814 0 0115.61-10.567h44.484a16.813 16.813 0 0115.611 10.567l6.981 17.453H44.832l6.98-17.453zm-18.19 56.613c-6.724 0-11.208-4.47-11.208-11.174S26.9 67.25 33.624 67.25s16.812 10.056 16.812 16.76c0 6.704-10.087 5.587-16.812 5.587zm112.084 0c-6.725 0-16.812 1.117-16.812-5.587s10.087-16.76 16.812-16.76c6.725 0 11.208 4.47 11.208 11.173 0 6.704-4.483 11.174-11.208 11.174z" _fill="#fff" opacity=".2"/></g><defs><clipPath id="clip0_5938_14402"><path pid="1" _fill="#fff" d="M0 0h119v135H0z"/></clipPath></defs>'
  }
})
