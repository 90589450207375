/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sante': {
    width: 34,
    height: 43,
    viewBox: '0 0 34 43',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M10.666.332a3.25 3.25 0 00-3.25 3.25h-3.25a3.25 3.25 0 00-3.25 3.25v30.333a3.25 3.25 0 003.25 3.25h13V38.25h-13a1.083 1.083 0 01-1.083-1.084V6.832a1.083 1.083 0 011.083-1.083h3.25a3.25 3.25 0 003.25 3.25h8.667a3.25 3.25 0 003.25-3.25h3.25a1.083 1.083 0 011.084 1.083v16.25h2.166V6.832a3.25 3.25 0 00-3.25-3.25h-3.25a3.25 3.25 0 00-3.25-3.25h-8.666zm-1.083 3.25a1.083 1.083 0 011.083-1.083h8.667a1.083 1.083 0 011.084 1.083v2.167a1.083 1.083 0 01-1.084 1.083h-8.666a1.083 1.083 0 01-1.084-1.083V3.582zm4.333 13v-3.25h2.167v3.25h3.25v2.167h-3.25v3.25h-2.166v-3.25h-3.25v-2.167h3.25zm4.334 9.75a1.084 1.084 0 011.083-1.083h13a1.083 1.083 0 011.084 1.083v7.355a7.583 7.583 0 01-3.377 6.31L26.434 42.4a1.083 1.083 0 01-1.202 0l-3.605-2.405a7.585 7.585 0 01-3.377-6.308v-7.356zm2.166 1.083v6.272a5.417 5.417 0 002.413 4.507l3.004 2.005 3.004-2.005a5.416 5.416 0 002.413-4.506v-6.273H20.416zm5.1 8.35l4.333-4.334-1.532-1.532-3.567 3.568-1.4-1.4-1.533 1.53 2.167 2.168a1.083 1.083 0 001.532 0z" _fill="#606060"/>'
  }
})
