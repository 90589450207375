/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'calendrier': {
    width: 15,
    height: 15,
    viewBox: '0 0 15 15',
    data: '<path pid="0" d="M10.43 4.453a.118.118 0 01-.117.117h-.82a.118.118 0 01-.118-.117V3.75h-3.75v.703a.118.118 0 01-.117.117h-.82a.118.118 0 01-.118-.117V3.75H2.695v1.992h9.61V3.75H10.43v.703z" _fill="#989898" fill-opacity=".25"/><path pid="1" d="M12.89 2.695h-2.46v-.937a.118.118 0 00-.117-.117h-.82a.118.118 0 00-.118.117v.937h-3.75v-.937a.118.118 0 00-.117-.117h-.82a.118.118 0 00-.118.117v.937H2.11c-.26 0-.47.21-.47.47v9.726c0 .259.21.468.47.468h10.78c.26 0 .47-.21.47-.468V3.164c0-.26-.21-.469-.47-.469zm-.585 9.61h-9.61V6.738h9.61v5.567zm0-6.563h-9.61V3.75H4.57v.703c0 .065.053.117.117.117h.82a.118.118 0 00.118-.117V3.75h3.75v.703c0 .065.053.117.117.117h.82a.118.118 0 00.118-.117V3.75h1.875v1.992z" _fill="#636363"/>'
  }
})
