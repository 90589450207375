/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'retour': {
    width: 44,
    height: 43,
    viewBox: '0 0 44 43',
    data: '<circle pid="0" cx="21.969" cy="21.406" r="21.094" _fill="#0269AD"/><path pid="1" d="M20.281 16.39v-2.578a.843.843 0 00-1.44-.596l-5.906 5.906a.842.842 0 000 1.193l5.906 5.906a.844.844 0 001.44-.596v-2.612c.24-.017.477-.025.712-.025 4.578 0 6.951 3.014 7.055 3.147a.84.84 0 001.356-.018.843.843 0 00.159-.492c0-7.699-6.823-9.012-9.282-9.236zm.712 4.91c-.546 0-1.107.039-1.667.113a.845.845 0 00-.732.837v1.338l-3.87-3.87 3.87-3.869v1.338c0 .466.397.844.862.844.768 0 6.846.17 8.167 5.421-1.396-1.028-3.612-2.151-6.63-2.151z" _fill="#fff"/>'
  }
})
