/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'send': {
    width: 50,
    height: 50,
    viewBox: '0 0 50 50',
    data: '<circle pid="0" cx="25" cy="25" r="25" _fill="#02548B"/><path pid="1" d="M40 25L16 36v-9.847L24.97 25 16 23.847V14l24 11z" _fill="#fff"/>'
  }
})
