/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'messengerShare': {
    width: 1312,
    height: 1312,
    viewBox: '0 0 1312 1312',
    data: '<path pid="0" d="M.053 636.265C.053 270.545 286.506 0 656.053 0S1312 270.6 1312 636.265c0 365.665-286.46 636.215-656.002 636.215-66.147 0-130.107-8.75-189.694-25.15a52.475 52.475 0 00-34.986 2.73l-130.653 57.4a52.39 52.39 0 01-25.094 4.4 52.436 52.436 0 01-24.209-7.93 52.598 52.598 0 01-17.62-18.4 52.494 52.494 0 01-6.877-24.53l-3.827-116.99a53.03 53.03 0 00-5-20.35 53.037 53.037 0 00-12.493-16.82 622.668 622.668 0 01-153.48-213.102A622.555 622.555 0 01.107 636.32l-.054-.055zm454.826-119.72L262.453 822.679c-19.134 28.973 17.493 62.265 44.826 41l207.187-156.894c14.213-10.933 32.8-10.933 47.56 0l153.067 114.8c45.919 34.44 111.519 21.867 142.133-26.24l192.424-306.133c19.14-28.973-17.49-61.773-44.82-41L797.639 605.105c-13.667 10.934-32.8 10.934-47.013 0l-153.067-114.8a98.4 98.4 0 00-142.68 26.24z" _fill="#66B4FB"/>'
  }
})
