/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'alert': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M9 2.25a6.75 6.75 0 100 13.5 6.75 6.75 0 000-13.5zM.75 9a8.25 8.25 0 1116.5 0A8.25 8.25 0 01.75 9z" _fill="#909090"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M9.75 5.25v4.5h-1.5v-4.5h1.5zM9 11.625a.75.75 0 01.75.75v.375a.75.75 0 01-1.5 0v-.375a.75.75 0 01.75-.75z" _fill="#909090"/>'
  }
})
