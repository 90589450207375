/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chevron-right': {
    width: 21,
    height: 21,
    viewBox: '0 0 21 21',
    data: '<path pid="0" d="M6.622 5.036a1.11 1.11 0 01.355-1.802 1.086 1.086 0 011.193.24l6.12 6.244a1.113 1.113 0 010 1.564l-6.12 6.244a1.087 1.087 0 01-1.548 0 1.11 1.11 0 010-1.563l5.018-5.466-5.018-5.46z" _fill="#909090"/>'
  }
})
