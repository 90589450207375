/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chevron-down': {
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
    data: '<path pid="0" d="M10.642 4.413a.74.74 0 011.202.237.724.724 0 01-.16.796L7.521 9.525a.742.742 0 01-1.042 0l-4.163-4.08a.724.724 0 010-1.032.74.74 0 011.042 0L7.002 7.76l3.64-3.346z" _fill="#fff"/>'
  }
})
