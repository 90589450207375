/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'download': {
    width: 15,
    height: 15,
    viewBox: '0 0 15 15',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M2.232 12.653a.744.744 0 01.745-.744h8.932a.744.744 0 110 1.489H2.977a.744.744 0 01-.745-.745zm2.452-5.736a.744.744 0 011.052 0l.962.962V2.233a.744.744 0 111.49 0v5.646l.962-.962a.744.744 0 111.052 1.052L7.97 10.202a.744.744 0 01-1.052 0L4.684 7.97a.744.744 0 010-1.052z" _fill="#737D84"/>'
  }
})
