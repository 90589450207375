/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'play': {
    width: 12,
    height: 14,
    viewBox: '0 0 12 14',
    data: '<path pid="0" d="M11.394 8.046L1.85 13.584c-.81.47-1.85-.099-1.85-1.045V1.463C0 .518 1.038-.052 1.85.419l9.544 5.538a1.202 1.202 0 010 2.089z" _fill="#0269AD"/>'
  }
})
