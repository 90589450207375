/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'defile': {
    width: 24,
    height: 15,
    viewBox: '0 0 24 15',
    data: '<path pid="0" d="M1.5 2L12 12.5 22.5 2" _stroke="#909090" stroke-width="3.148"/>'
  }
})
