/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'map': {
    width: 33,
    height: 33,
    viewBox: '0 0 33 33',
    data: '<rect pid="0" opacity=".2" width="32.913" height="32.913" rx="5.877" _fill="#252D5C"/><path pid="1" d="M23.526 21.576L22.501 18.5h-1.125l.625 3H11l.625-3h-1.125l-1.026 3.076c-.26.784.201 1.424 1.026 1.424h12c.825 0 1.286-.64 1.025-1.424zm-3.275-9.826a3.75 3.75 0 00-7.5 0c0 3.581 3.75 7.5 3.75 7.5s3.75-3.919 3.75-7.5zm-5.775.045a2.024 2.024 0 114.048 0 2.024 2.024 0 01-4.048 0z" _fill="#252D5C"/>'
  }
})
